@import url("https://fonts.googleapis.com/css2?family=Days+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
/*  */

* {
  color: white;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

button {
  font-family: "Days One", "Franklin Gothic Medium", "Arial Narrow", Arial,
    sans-serif;
}

body {
  /* position: relative; */
  /* height: 100vh;
  width: 100vw; */
  background: radial-gradient(circle, #2e287e, #1a0933, #05001b, #000000 90%);
  /* overflow: hidden; */
  font-family: "Days One", "Franklin Gothic Medium", "Arial Narrow", Arial,
    sans-serif;
}

.dexscreen {
  font-family: "Inter", sans-serif;
}

html {
  scroll-behavior: smooth;
}

/* BOT-HEADER */
.color-title-header {
  color: #66b2ca;
}

.color-text-header {
  color: rgb(177, 171, 171);
}

.flex-card-header {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.bordercontainer-how-to-use {
  border: 2px solid #b94fb6;
  padding: 40px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  background-color: #48d6d21a;
}

/* ////////////////////gsap content flash///// */
.card-faqs,
.title-lg,
.text-lg,
.btn {
  opacity: 0;
  visibility: hidden; /* Ensures the elements are not interactable */
}

.no-js .card-faqs,
.no-js .title-lg,
.no-js .text-lg,
.no-js .btn {
  opacity: 1;
  visibility: visible;
}

header {
  width: 100vh;
}

/* //////////////////////////////////////////////////////// */
.guide-section {
  margin-top: 2rem;
  padding: 1.5rem;
  background: #807ad4;
  border: 2px solid #b94fb6;
  border-radius: 8px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.guide-section h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.guide-section ol {
  padding-left: 1.5rem;
}

.guide-section ol li {
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.guide-section p {
  margin-top: 1rem;
  font-size: 0.95rem;
  color: #47129b;
}

/* CONTRACT */

.copy-contract {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.btn-copy {
  background-color: #055c6b;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

.btn-copy:hover {
  background-color: #0056b3;
}

.copied-msg {
  margin-left: 10px;
  color: green;
  font-size: 14px;
}

/* NFT */
.minting-section {
  margin-top: 20px;
  padding: 20px;
  background: #1a1a1a; /* Dark background for contrast */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.minting-title {
  color: #ffffff;
  font-size: 24px;
  margin-bottom: 15px;
  text-align: center;
}
/* ///////////////////////////////// */
/* components/Preloader.css */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000; /* Adjust to match your theme */
  color: #fff;
  z-index: 9999;
}

.preloader-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #fff;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

.sticky {
  position: fixed;
  top: 0.7%;
  left: 3%;
  right: 3%;
  border-radius: 20px;
  padding: 0;
  .container {
    padding: 5px 0;
  }
  /*background: rgba(0, 0, 0, 0.8);
  */
  background: #807ad4;
  transition: all 0.2s ease-in-out;
  /*box-shadow: 0px 0px 10px 5px rgba(132, 206, 14, 0.5);
  */ /*Adjustbackgroundcolorasneeded*/
  z-index: 1000;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.socials-faqs {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 40px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* gsap opacity 0 */
.title-lg,
nav {
  opacity: 0;
}
.display-none-desktop {
  display: none;
}

/* ///////////////////////////////// */

a {
  text-decoration: none;
}

.container {
  width: 80%;
  margin: 0 auto;
  padding: 20px 10px;
  overflow-x: hidden;
}

.section-spacing {
  margin: 100px 0;
}

.bg-img {
  background: url("/public/img/background-img.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

/* NAVBAR */

.dextool-icon {
  width: 80px;
  height: auto;
}
.x-icon {
  width: 25px;
  height: auto;
}

.btn-navBar {
  display: flex;
  background: linear-gradient(90deg, #f334a9 46.4%, #66b2ca 100%);
  border: 1.32708px solid #000000;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 10px;
  width: 100%;
  gap: 10px;
  font-size: 1.15rem;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  font-size: small;
  /* margin-right: 10px; */
}

.flex-navBar {
  display: flex;
  justify-content: space-between;
  list-style: none;
  align-items: center;

  padding: 3px;
}

.line {
  border-bottom: 1px solid #5fddf3;
}
.navbar-hover-effect {
  transition: all 0.5s ease-in-out;
  display: inline-block;
}

.navbar-hover-effect:hover {
  color: #66b2ca;
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform-origin: center;
}

.logo-hamburger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.logo {
  font-size: 1.5rem;
  color: #f334a9;
  text-decoration: none;
  padding: 10px;
}

.hamburger {
  display: none; /* Visible only on mobile */
}

/* Utility classes */
/* HEADER */
.title-lg {
  font-size: 3rem;
}
.title-xl {
  font-size: 6rem;
}
.text-lg {
  font-size: 1.5rem;
}
.text-sm {
  font-size: 0.8rem;
}

.flex-header {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 100px 0;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.grid-header {
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}

.btn {
  display: flex;
  background: linear-gradient(90deg, #f334a9 46.4%, #66b2ca 100%);
  border: 1.32708px solid #000000;
  align-items: center;
  justify-content: center;
  padding: 16px;
  text-decoration: none;
  border-radius: 10px;
  width: 80%;
  gap: 10px;
  font-size: 1.15rem;
  cursor: pointer;
}

.btn:hover {
  color: #66b2ca;
  transition: all 0.5s ease-in-out;

  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

.flip-horizontal {
  transform: scaleX(-1);
}

.grid {
  display: grid;
  grid-template-columns: 1.5fr 0.9fr;
  align-items: center;
  gap: 10px;
}

.header-img {
  width: 100%;
}

/* ABOUT */

.grid-about {
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
}

.flex-text-about {
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
}

.About-color {
  color: #47a6b6;
  text-transform: uppercase;
}

.About-font {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.1rem;
  margin: 0;
}

.about-text {
  color: rgb(141, 141, 141);
  line-height: 2;
  font-weight: bold;
}

.about-heading {
  padding: 30px 0;
}
.flex-icon-text {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.flex-container-about-icon-text {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.svg-width-about {
  width: 200px;
  height: auto;
}

.grid-about {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 20px;
}

.flip-horizontal {
  transform: scaleX(-1);
}

.width {
  max-width: 100%;
}

.text-center {
  text-align: center;
  color: gray;
  padding: 10px 0;
  margin-top: 20px;
}

/* ******************************* */
/* Hamburger Button Styles */
.hamburger {
  display: none;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 30px;
  height: 30px;
  margin-left: auto; /* Align it to the right */
}

.hamburger .bar {
  width: 100%;
  height: 3px;
  background-color: #64b0c8;
  transition: all 0.3s ease;
}

/* Hamburger animation */
.hamburger .bar.open:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger .bar.open:nth-child(2) {
  opacity: 0;
}

.hamburger .bar.open:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* ******************************* */

/* Mobile Menu Styles */
.flex-navBar {
  display: flex;
  justify-content: space-between;
  list-style: none;
  align-items: center;
  padding: 3px;
  transition: max-height 0.3s ease-in-out;
}

.width {
  width: 100%;
}

/* ROADMAP */

/* Container for the whole roadmap */
.roadmap-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  /* background-color: #0f0e17; */
  color: #ffffff;
}

.roadmap-title {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #47a6b6;
}

.roadmap-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-width: 600px;
}

/* Individual roadmap phase container */
.roadmap-phase-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.roadmap-phase {
  width: 100%;
  padding: 20px;
  margin: 20px 0;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  color: #ffffff;
}

.roadmap-phase h2 {
  font-size: 1.5rem;
  color: #ffffff;
  margin-bottom: 10px;
}

.roadmap-phase p {
  font-size: 1rem;
  color: #d0d0d0;
  line-height: 1.5;
}

/* Dotted line styles */
.dotted-line {
  width: 2px;
  height: 40px;
  background: repeating-linear-gradient(
    to bottom,
    #ffffff,
    #ffffff 3px,
    transparent 3px,
    transparent 6px
  );
  position: relative;
}

.vertical {
  margin: 10px 0;
}

/* Add arrow to the end of each dotted line */
.dotted-line::after {
  content: "";
  position: absolute;
  bottom: -8px; /* Position it at the end of the line */
  left: 50%;
  transform: translateX(-50%) rotate(45deg); /* Center and rotate the arrow */
  width: 8px;
  height: 8px;
  border-right: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
}

/* UTILITY */

.utility-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  width: 60%;
  margin: 0 auto;
}

.utility-title {
  font-size: 2rem;
  /* color: #66b2ca; */
}

.utility-card-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  justify-content: center;
  align-items: center;
}

.utility-card-title {
  color: #66b2ca;
  display: flex;
  justify-content: center;
  align-items: center;
}

.utility-card {
  position: relative;
  padding: 18px;
  text-align: center;
  font-size: 16px;
  border-radius: 30px;
  background-color: #0a4442c9;
  /* background-image: linear-gradient(to right, #64b0c8, #f334a9); */
  padding: 100px 30px;
  /* border: 2px red solid; */
}
.utility-svg {
  position: absolute;
  top: -15%;
  left: 40%;
  /* top: 50%; */
}
.svg-utility {
  height: auto;
  width: 100%;
}
.video-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
}

.text-align-center {
  text-align: center;
}

/* Tokenomics */

.Tokenomics-title {
  text-align: center;
  font-size: 3rem;
  padding: 50px 0;
  color: #47a6b6;
}

.video-curve {
  border-radius: 20px;
}

.token-data {
  display: flex;
  gap: 20px;
  margin-top: 30px;
}

.btn-token {
  display: flex;
  background: linear-gradient(90deg, #f334a9 46.4%, #66b2ca 100%);
  border: 1.32708px solid #000000;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  text-decoration: none;
  border-radius: 10px;
  /* width: 70%; */
  gap: 10px;
  font-size: 1.15rem;
}

hr {
  background-color: #66b3ca62;
  margin: 20px 0;
  /* border: none; */
  border: 0.5px solid #66b3ca62;
}

.title-token {
  margin-bottom: 0px;
}

.HowToBuy-title {
  font-size: 2.5rem;
  margin-top: 30px;
  color: #47a6b6;
}

.HowToBuy-Card {
  background-color: #1c1b2a;
  padding: 30px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  display: flex;
  gap: 40px;
  margin-top: 50px;
}

.flex-1-HowToBuy {
  display: flex;
  flex-direction: column;
}

.flex-container-HowToBuy {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-content: center;
  gap: 50px;
}

.img-HowToBuy {
  width: 60%;
  height: auto;
}
.gray-text {
  color: rgb(184, 182, 182);
}
.btn-HowToBuy {
  display: flex;
  background: linear-gradient(90deg, #f334a9 46.4%, #66b2ca 100%);
  border: 1.32708px solid #000000;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  text-decoration: none;
  border-radius: 10px;
  width: 60%;
  /* width: 70%; */
  gap: 10px;
  margin: auto;
  font-size: 1.15rem;
  margin-top: 100px;
  z-index: 5;
  position: relative;
}

.hr-HowToBuy {
  background-color: #d9d9d91a;

  /* background-color: red; */
  width: 100%;

  /*border: none;
   */
  position: absolute;
  /* right: 0;
  left: 0; */
  top: 20%;
  border: 0.5px solid #d9d9d91a;
  z-index: 1;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.container-hr {
  position: relative;
}

/* FAQS */

/* Main FAQ container styling */
.faqs-title {
  font-size: 2.5rem;
  margin-bottom: 40px;
  text-align: center;
  color: #47a6b6;
}

.text-faqs {
  color: #aaa7a7;
  text-align: center;
}

.card-container-faqs {
  display: flex;
  flex-direction: column;
  margin: 40px 0;
  gap: 20px;
}

.card-faqs {
  background-color: #48d6d21a;
  border: 2px solid #b94fb6;
  border-radius: 30px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  padding: 40px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  background-color: #66b2ca33;
}

.faq-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
}

.title-card-faqs {
  color: #5fddf3;
  font-size: 1.3rem;
  margin: 0;
}

.faq-toggle {
  font-size: 1.5rem;
  color: #5fddf3;
  transition: transform 0.3s ease, color 0.3s ease;
}

.faq-toggle.open {
  transform: rotate(180deg); /* Rotates the icon when open */
  color: #ff00ff; /* Optional: Change color when open */
}

/* FAQ Answer text with transition effect */
.text-card-faqs {
  color: #aaa7a7;
  padding: 0 20px;
  font-size: 1rem;
  line-height: 1.6;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, padding 0.5s ease;
  opacity: 0;
}

/* When the answer is open */
.text-card-faqs.show {
  max-height: 200px; /* Set a max height larger than the expected content */
  padding-bottom: 15px; /* Add padding when open */
  opacity: 1;
}

/* MEDIA QUERRY */
@media (max-width: 760px) {
  .container {
    width: 90%;
  }
  .title-lg {
    font-size: 2rem;
  }

  .text-lg {
    font-size: 1.5rem;
  }

  .flex-card-header {
    flex-grow: wrap;
  }
  /*  */
  .btn-copy {
    width: 100%;
  }
  /*  */
  .display-none-desktop {
    display: block;
  }

  .display-none-mobile {
    display: none;
  }

  .section-spacing {
    margin: 50px 0;
  }

  .grid-header {
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }

  .grid {
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }

  .hamburger {
    display: flex;
    /* padding: 10px; */
  }

  .btn {
    width: 100%;
    font-size: 0.9rem;
  }

  /* Hide menu by default on mobile */
  .flex-navBar {
    flex-direction: column;
    align-items: center;
    /* justify-content: flex-start; */
    gap: 10px;
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  /* Show menu when the state is open */
  .flex-navBar.show {
    visibility: visible;
    max-height: 500px; /* Adjust as needed */
  }

  .grid-about {
    grid-template-columns: 1fr;
    display: grid;
  }

  .grid-2 {
    order: 2;
    display: grid;
  }

  .grid-1 {
    order: 1;
    display: grid;
  }

  /* ABOUT */
  .flex-icon-text {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: start;
    padding: 30px 0;
  }
  .svg-width-about {
    width: 100px;
    height: auto;
  }

  /* ROADMAP */

  /* Utility */
  .utility-flex {
    width: 100%;
  }
  .utility-card-container {
    grid-template-columns: 1fr;
  }

  /* Tokenomics */
  .token-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    gap: 20px;
    margin-top: 30px;
  }

  .Tokenomics-title {
    font-size: 2rem;
  }

  /* How To Buy */
  .flex-container-HowToBuy {
    grid-template-columns: 1fr;
    gap: 50px;
  }

  .HowToBuy-Card {
    flex-direction: column;
  }

  /* FAQ */
  .socials-faqs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    gap: 20px;
    flex-direction: column;
  }
}

@media (min-width: 1500px) {
  .title-lg {
    font-size: 5rem;
  }
  .about-text {
    font-size: 1.5rem;
  }

  .container {
    width: 65%;
    margin: 0 auto;
    padding: 20px 10px;
    overflow-x: hidden;
  }
}

@media (max-width: 1180px) {
  .display-none-desktop {
    display: block;
  }

  .display-none-mobile {
    display: none;
  }

  .section-spacing {
    margin: 50px 0;
  }

  .grid-header {
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }

  .grid {
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }

  .hamburger {
    display: flex;
    /* padding: 10px; */
  }

  .btn {
    width: 100%;
    font-size: 0.9rem;
  }

  /* Hide menu by default on mobile */
  .flex-navBar {
    flex-direction: column;
    align-items: center;
    /* justify-content: flex-start; */
    gap: 10px;
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  /* Show menu when the state is open */
  .flex-navBar.show {
    visibility: visible;
    max-height: 500px; /* Adjust as needed */
  }

  .grid-about {
    grid-template-columns: 1fr;
    display: grid;
  }

  .grid-2 {
    order: 2;
    display: grid;
  }

  .grid-1 {
    order: 1;
    display: grid;
  }
}

/* //////////////////////////////////////////////////
/* /////////////////////////////////////////////////// */
